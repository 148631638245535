.Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 30%;
    height: 70vh;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
  }
  
  .Modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 55;
  }

  .Modal__close {
    position: absolute;
    top: 5px;
    right: -30px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: 400;
    font-size: 24px;
  }

  h3 {
    position: absolute;
    top: 0px;
    right: 20%;
  }

  .codesList {
    grid-area: content;
    display: block;
    margin-bottom: 0.3vh;
    height: 35vh;
  }
  ul li {
    display: inline-block;
  }
  .codesView {
    height: 85%;
    overflow-y: scroll;
    margin-bottom: 5px;
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: #cacfd1;

  }

  .removeButton {
    min-width: 30px;
    min-height: 30px;
    border: none;
  }

  .codeTag {
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: #cacfd1;
    margin:2px;
  }

  .buttonsBlock{
    display: inline;
    grid-area: footer;
    place-self: end;

  }

  .modalContent{
    display: grid;
    position: absolute;
    width: 90%;
    top: 10%;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 35vh 15vh 10vh;
    grid-template-areas:
      "content content"
      "dateFrom dateTo"
      ". footer";
      row-gap: 0.6vh;
  }

  .dateFrom {
    grid-area: dateFrom;
  }

  .dateTo {
    grid-area: dateTo;
  }

  button {
    min-width: 100px;
    min-height: 30px;
    border-color: #cacfd1;
    background-color: #ffffff;;
    color: #333;
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    max-width: 100%;
    font-weight: 400;
    font-size: 14px;
    margin-right: 5px;
  }
