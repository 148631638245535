$base-font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
$base-accent: #837aeb;
$base-label-color: #8c8c8c;
$base-link-color: #837aeb;
$base-icon-color: #99a1a8;
$base-text-color: #333;
$base-bg: #fff;
$base-border-color: #e8eaeb;
$base-border-radius: 2px;
$base-success: #5cb85c;
$base-warning: #f0ad4e;
$base-danger: #d9534f;
$base-hover-color: #333;
$base-hover-bg: rgba(0, 0, 0, 0.04);
$base-focus-color: #fff;
$base-focus-bg: #837aeb;
$base-invalid-color: #d9534f;
$base-invalid-faded-border-color: rgba(217, 83, 79, 0.4);
$base-border-radius-small: 1px;
$base-border-radius-large: 4px;
$button-normal-color: #333;
$button-normal-bg: #fff;
$button-normal-border-color: #cacfd1;
$button-default-color: #fff;
$button-default-bg: #837aeb;
$button-default-border-color: #6e64e8;
$button-danger-color: #fff;
$button-danger-bg: #d9534f;
$button-danger-border-color: #d43f3a;
$button-success-color: #fff;
$button-success-bg: #5cb85c;
$button-success-border-color: #4cae4c;
$list-border-color: #e8eaeb;
$list-group-color: #99a1a8;
$list-group-header-bg: #fafafa;
$list-normal-color: #333;
$list-item-hover-bg: rgba(0, 0, 0, 0.04);
$list-item-selected-bg: rgba(0, 0, 0, 0.1);
$list-item-focused-selected-bg: rgba(131, 122, 235, 0.7);
$list-focused-bg: #837aeb;
$list-item-active-bg: #837aeb;
$fieldset-field-label-color: #8c8c8c;
$checkbox-border-color: #e8eaeb;
$checkbox-focused-borderd-color: #837aeb;
$checkbox-bg: #fff;
$checkbox-hover-border-color: #5145e3;
$checkbox-active-icon-bg: rgba(96, 96, 96, 0.2);
$button-group-normal-selected-color: #333;
$button-group-normal-selected-bg: rgba(0, 0, 0, 0.17);
$button-group-default-selected-color: #837aeb;
$button-group-default-selected-bg: rgba(131, 122, 235, 0.3);
$button-group-danger-selected-color: #d9534f;
$button-group-danger-selected-bg: rgba(217, 83, 79, 0.3);
$button-group-success-selected-color: #5cb85c;
$button-group-success-selected-bg: rgba(92, 184, 92, 0.3);
$scrollable-scroll-bg: rgba(69, 56, 225, 0.8);
$scrollable-scrollbar-active-bg: rgba(191, 191, 191, 0.2);
$switch-border-color: #e8eaeb;
$switch-on-color: #333;
$switch-container-active-bg: rgba(96, 96, 96, 0.2);
$switch-off-color: #999999;
$tabs-tab-color: #333;
$tabs-tab-selected-color: #333;
$tabs-tab-bg: #f7f7f7;
$tabs-tab-selected-bg: #fff;
$tabs-border-color: #e8eaeb;
$tabs-focused-border-color: #837aeb;
$tabs-tab-hover-bg: #fff;
$badge-color: #fff;
$badge-bg: #6c63d4;
$navbar-tab-color: #333;
$navbar-tab-selected-color: #333;
$navbar-tab-bg: #f7f7f7;
$navbar-tab-selected-bg: #fff;
$overlay-content-bg: #fff;
$overlay-shader-bg: rgba(255, 255, 255, 0.8);
$overlay-focus-border-color: #e8eaeb;
$textbox-search-icon-color: #99a1a8;
$texteditor-color: #333;
$texteditor-placeholder-color: #999999;
$texteditor-bg: #fff;
$texteditor-filled-bg: rgba(51, 51, 51, 0.05);
$texteditor-border-color: #e8eaeb;
$texteditor-focused-border-color: #837aeb;
$texteditor-hover-border-color: rgba(131, 122, 235, 0.4);
$dropdowneditor-button-hover-bg: #e6e6e6;
$dropdowneditor-button-active-bg: #b3b3b3;
$dropdowneditor-icon-color: #99a1a8;
$dropdowneditor-icon-active-color: #99a1a8;
$numberbox-spin-active-bg: #b3b3b3;
$numberbox-spin-hover-border-color: transparent;
$numberbox-spin-icon-color: #99a1a8;
$calendar-color: #333;
$calendar-header-color: #99a1a8;
$calendar-cell-other-color: #b0b0b0;
$calendar-bg: #fff;
$calendar-hover-bg: #edf0f2;
$calendar-cell-selected-bg: #837aeb;
$calendar-cell-active-bg: rgba(96, 96, 96, 0.2);
$calendar-border-color: #e8eaeb;
$calendar-navigator-border-color: #e8eaeb;
$calendar-navigator-hover-border-color: #e8eaeb;
$calendar-shevron-icon-color: #837aeb;
$loadindicator-bg: #837aeb;
$treeview-focused-bg: #837aeb;
$treeview-hover-bg: rgba(0, 0, 0, 0.04);
$treeview-border-color: #e8eaeb;
$treeview-item-selected-color: #333;
$treeview-spin-icon-color: #99a1a8;
$menu-popup-bg: #fff;
$menu-item-hover-bg: rgba(0, 0, 0, 0.04);
$menu-color: #333;
$menu-item-hovered-color: #333;
$menu-item-expanded-color: #333;
$menu-item-selected-bg: #e6e6e6;
$menu-popup-border-color: #e8eaeb;
$selectbox-list-bg: #fff;
$toolbar-bg: #fff;
$popup-title-bg: transparent;
$tileview-color: #333;
$toast-color: #fff;
$toast-info-bg: #837aeb;
$toast-warning-bg: #f0ad4e;
$toast-error-bg: #d9534f;
$toast-success-bg: #5cb85c;
$progressbar-bg: #dddddd;
$progressbar-range-bg: #837aeb;
$tooltip-color: #333;
$tooltip-bg: #fff;
$tooltip-border-color: #e8eaeb;
$slider-bar-bg: #e8eaeb;
$slider-range-bg: #837aeb;
$gallery-indicator-bg: #fff;
$gallery-indicator-item-selected-bg: #837aeb;
$gallery-indicator-focused-bg: #4538e1;
$gallery-navbutton-hover-color: rgba(131, 122, 235, 0.5);
$gallery-nav-arrow-color: #fff;
$gallery-navbutton-active-color: rgba(131, 122, 235, 0.7);
$loadpanel-content-bg: #fff;
$tagbox-tag-color: #333;
$tagbox-tag-bg: #dddddd;
$tagbox-tag-button-remove-bg: #aaaaaa;
$radiogroup-checked-bg: #837aeb;
$radiobutton-active-bg: rgba(96, 96, 96, 0.2);
$accordion-color: #333;
$accordion-title-color: #333;
$accordion-item-title-opened-bg: transparent;
$accordion-item-border-color: #e8eaeb;
$accordion-item-focused-border-color: #837aeb;
$accordion-title-active-color: #333;
$accordion-item-hover-bg: rgba(0, 0, 0, 0.04);
$accordion-icon-active-color: #333;
$colorbox-overlay-bg: #fff;
$datagrid-base-color: #333;
$datagrid-base-background-color: #fff;
$datagrid-border-color: #e8eaeb;
$datagrid-columnchooser-item-color: #99a1a8;
$datagrid-columnchooser-font-weight: normal;
$datagrid-drag-header-border-color: rgba(131, 122, 235, 0.5);
$datagrid-selection-bg: #d1e0ed;
$datagrid-row-selected-border-color: #e1ecf5;
$datagrid-row-selected-color: #333;
$datagrid-row-focused-color: #fff;
$datagrid-row-focused-bg: #9c95ef;
$datagrid-hover-bg: #edf0f2;
$datagrid-menu-icon-color: #99a1a8;
$datagrid-cell-modified-border-color: rgba(92, 184, 92, 0.5);
$datagrid-row-invalid-faded-border-color: rgba(217, 83, 79, 0.4);
$datagrid-nodata-color: #99a1a8;
$datagrid-group-row-color: #99a1a8;
$datagrid-group-row-bg: #fafafa;
$datagrid-search-color: #fff;
$datagrid-spin-icon-color: #99a1a8;
$datagrid-search-bg: #837aeb;
$datagrid-row-error-color: #fff;
$datagrid-row-error-bg: #e89895;
$datagrid-link-color: #837aeb;
$datagrid-focused-border-color: #9c95ef;
$datagrid-editor-bg: #fff;
$datagrid-columnchooser-bg: #fff;
$datagrid-row-alternation-bg: #fafafa;
$datagrid-summary-color: #99a1a8;
$pager-page-selected-color: #333;
$pager-page-selected-bg: #e8eaeb;
$pivotgrid-area-color: #99a1a8;
$pivotgrid-totalcolor: rgba(245, 245, 245, 0.7);
$pivotgrid-grandtotalcolor: whitesmoke;
$pivotgrid-field-area-text-color: #565e65;
$pivotgrid-spin-icon-color: #99a1a8;
$fileuploader-filename-color: #333;
$fileuploader-falename-status-color: #999999;
$fileuploader-border-color: #e8eaeb;
$scheduler-base-border-color: rgba(232, 234, 235, 0.6);
$scheduler-accent-border-color: #cdd1d3;
$scheduler-appointment-text-color: #fff;
$scheduler-appointment-base-color: #837aeb;
$scheduler-appointment-start-color: rgba(0, 0, 0, 0.3);
$scheduler-first-month-border-color: #b1b8bc;
$scheduler-workspace-focused-cell-color: #eae9fc;
$scheduler-current-time-cell-color: #837aeb;
$scheduler-time-indicator-color: #c1bdf5;
$form-field-item-color: #8c8c8c;
$form-group-border-color: #d5d9da;
$filterbuilder-text-color: #333;
$filterbuilder-text-focus-color: #fff;
$filterbuilder-plus-icon-color: rgba(92, 184, 92, 0.3);
$filterbuilder-remove-icon-color: rgba(217, 83, 79, 0.3);
$filterbuilder-group-operation-color: rgba(217, 83, 79, 0.3);
$filterbuilder-item-field-color: rgba(131, 122, 235, 0.3);
$filterbuilder-item-operator-color: rgba(92, 184, 92, 0.3);
$filterbuilder-item-value-color: rgba(232, 234, 235, 0.5);
$filterbuilder-menu-icon-color: #99a1a8;
$drawer-shader-background-color: rgba(0, 0, 0, 0.5);
